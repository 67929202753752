(function() {
    'use strict';
    angular
        .module('consulatApp')
        .factory('Carte', Carte)
        .factory('CarteMultiParams', CarteMultiParams);

    Carte.$inject = ['$resource', 'DateUtils'];
    CarteMultiParams.$inject = ['$resource', 'DateUtils'];



    function CarteMultiParams ($resource, DateUtils) {
        var resourceUrl =  'api/cartes/findByParams';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateNaissance = DateUtils.convertLocalDateFromServer(data.dateNaissance);
                    }
                    return data;
                }
            }
        });
    }


    function Carte ($resource, DateUtils) {
        var resourceUrl =  'api/cartes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateCreate = DateUtils.convertLocalDateFromServer(data.dateCreate);
                        data.dateExpr = DateUtils.convertLocalDateFromServer(data.dateExpr);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateCreate = DateUtils.convertLocalDateToServer(copy.dateCreate);
                    copy.dateExpr = DateUtils.convertLocalDateToServer(copy.dateExpr);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dateCreate = DateUtils.convertLocalDateToServer(copy.dateCreate);
                    copy.dateExpr = DateUtils.convertLocalDateToServer(copy.dateExpr);
                    return angular.toJson(copy);
                }
            },

        });
    }
})();
