(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MembersDetailController', MembersDetailController);

        MembersDetailController.$inject = ['$scope','$filter','$http','DataUtils','Members', 'InfoAssociationByMember', '$window', '$uibModalInstance', 'Country', 'entity', 'CityByStateId', 'Association', 'CityByCountryId', 'Poste', 'States', 'City', 'DocumentLegal', 'Adresses', 'InfoAssociation', 'InfoService', 'Consulat', 'Metier','Zone'];

    function MembersDetailController ($scope,$filter,$http,DataUtils,Members, InfoAssociationByMember, $window, $uibModalInstance, Country, entity, CityByStateId, Association, CityByCountryId,Poste, States, City, DocumentLegal, Adresses, InfoAssociation, InfoService, Consulat,Metier,Zone) {
        var vm = this;
        vm.members = entity;
        vm.members.dateNaissance = vm.members.dateNaissanceStr;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save =save;
        vm.changerAdresse = changerAdresse;
        vm.countriesVille = [];
        vm.etats = [];
        vm.documents = DocumentLegal.query();
        vm.reset = reset;
        vm.mariage = mariage;
        vm.inusa = inusa;
        vm.inusae = inusae;
        vm.annulerCarte = annulerCarte();
        vm.members.annulerCarte = false;
        vm.members.dateAnnulation = null;
        vm.members.motifAnnulation = null;

        vm.reset();


        function changerAdresse(bol) {
              }

        function annulerCarte() {
           if(!vm.members.annulerCarte){
               vm.members.dateAnnulation = null;
               vm.members.motifAnnulation = null;
           }
        }

         $scope.$watch("vm.members.candidate", function () {
            if(!vm.members.candidate){
                vm.members.candidate = false;
                vm.members.zoneId = null;
                vm.members.zoneLibelle = null;
            }


        });


        vm.setPhoto = function ($file, members) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        members.photo = base64Data;
                        members.photoContentType = $file.type;
                    });
                });
            }
        };

        vm.setSignature = function ($file, consulat) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        consulat.signature = base64Data;
                        consulat.signatureContentType = $file.type;
                    });
                });
            }
        };

        function reset () {
             if(vm.members.countryId==null)
            {

            vm.members.country = {
            autorite:null,
            cityId:null,
            dateDelivr:null,
            dateExpr:null,
            numero:null,
            etat:null,
            statesId:null,
            cityId:null
            };
        }

          if(vm.members.bfIdDocId==null)
        {
            vm.members.bfIdDoc = {
            autorite: null,
            cityId: null,
            dateDelivr: null,
            dateExpr: null,
            docId: '',
            numero: null,
            ville: null
            }

         }
        }

         function mariage () {
            if(vm.members.mariage==false)
            {
             vm.members.nomConjoint = null;
             vm.members.prenomConjoint = null;
             vm.members.memePaysConjoint = null;
             vm.members.noConjoint = null;
            }

         }

         function inusa () {
           if(vm.members.parentsUsa==false)
           {
            vm.members.infoParents.noPere = null;

           }

        }

        function inusae () {
            if(vm.members.mumInUsa==false)
            {
                vm.members.infoParents.noMere = null;

            }

         }



        function openCalendar (date) {
            vm.datePickerOpenStatus.date;

            vm.datePickerOpenStatus[date] = true;
         }

        if(vm.members.dateAnnulation!=null)
        {
            vm.members.dateAnnulation = new Date(vm.members.dateAnnulation);
        }

        if(vm.members.dateNaissance!=null)
        {
            vm.members.dateNaissance = new Date(vm.members.dateNaissance);
        }

        if(vm.members.dateNaissance!=null)
        {
            vm.members.dateNaissance = new Date(vm.members.dateNaissance);
        }

        if(vm.members.dateArrivee!=null)
        {
            vm.members.dateArrivee = new Date(vm.members.dateArrivee);
        }

        if(vm.members.bfIdDocId!=null)
        {
            vm.members.bfIdDoc.dateDelivr = new Date(vm.members.bfIdDoc.dateDelivr);
            vm.members.bfIdDoc.dateExpr = new Date(vm.members.bfIdDoc.dateExpr);

        }

        if(vm.members.countryId!=null)
        {
            vm.members.country.dateDelivr = new Date(vm.members.country.dateDelivr);
        }

        if(vm.members.countryId!=null)
        {
            vm.members.country.dateExpr = new Date(vm.members.country.dateExpr);
        }


if(vm.members.signature!=null)
{
        vm.members.signature="data:image/png;base64,"+vm.members.signature;
}


        var fileURLPDF;

        vm.etatCarte=etatCarte;

        vm.members.dateDelivr = new Date(vm.members.dateDelivr);

        vm.init = init;

        function init()
{
        InfoAssociationByMember.query({id:vm.members.id},
            function (data) {
               vm.members.associations=data;

              /*  angular.forEach(data, function (value, index) {
                var v = {};
                v = value;
                v.dateFonction = new Date(v.dateFonction);
                vm.members.associations.push(v);
            }); */



                }
            )


          /*  AdressesByMember.query({id:vm.members.id},
                function (data) {
                   vm.members.adresseDTO=data;
                    }
                )*/
}

vm.init();


        function save() {



          if(vm.members.bfIdDoc.docId=="")
             {
            vm.members.bfIdDoc.docId = null;

             }


        if(!vm.members.scanSignature)
            {
            if(vm.members.signature!=null)
            {
            var canvas = document.createElement("canvas");
        var dataURL = canvas.toDataURL( "image/png" );
        vm.members.signature =  vm.members.signature.substring( "data:image/png;base64,".length );

    }
    else
    {
        var canvas = document.createElement("canvas");
        var dataURL = canvas.toDataURL( "image/png" );
    }
}
else
{
    var canvas = document.createElement("canvas");
        var dataURL = canvas.toDataURL( "image/png" );

}
            vm.isSaving = true;
            if (vm.members.id !== null) {
                Members.update(vm.members, onSaveSuccess, onSaveError);
            } else {
                Members.save(vm.members, onSaveSuccess, onSaveError);
            }
           }

           function onSaveSuccess (resultat) {
            $scope.$emit('consulatApp:consulatUpdate', resultat);
            $uibModalInstance.close(resultat);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.clear = clear;
             /*****************************Gestionnaire des onglets**************************************/
             vm.steps = [
                'IDENTIFICATION',
                'ADRESS',
                'DOC IDENTIFICATION',
                'ASSOCIATION',
                 'ANNULATION CARTE',
                 'ELECTION'
            ];


            vm.selection = vm.steps[0];
            vm.error = false;
            vm.showOnglet = showOnglet;
            vm.getCurrentStepIndex = function() {
                return vm.steps.indexOf(vm.selection);
            };

                 function showOnglet(onglet) {
                var stepIndex = vm.getCurrentStepIndex();
                return (vm.selection.toUpperCase() === onglet.toUpperCase());
            }

          // Go to a defined step index
        vm.goToStep = function(index, editForm) {
            if (!editForm.$invalid || (vm.getCurrentStepIndex() > index) ) {
                if (!angular.isUndefined(vm.steps[index])) {
                    vm.selection = vm.steps[index];
                }
            }
        };
            vm.hasNextStep = function() {
                var stepIndex = vm.getCurrentStepIndex();
                var nextStep = stepIndex + 1;
                // Return true if there is a next step, false if not
                return !angular.isUndefined(vm.steps[nextStep]);
            };

            vm.hasPreviousStep = function() {
                var stepIndex = vm.getCurrentStepIndex();
                var previousStep = stepIndex - 1;
                // Return true if there is a next step, false if not
                return !angular.isUndefined(vm.steps[previousStep]);
            };

            vm.incrementStep = function() {
                if (vm.hasNextStep()) {
                    //Vérification des champs du step précedent
                    var stepIndex = vm.getCurrentStepIndex();
                    var nextStep = stepIndex + 1;
                    vm.selection = vm.steps[nextStep];
                }
            };


            vm.decrementStep = function() {
                if (vm.hasPreviousStep()) {
                    var stepIndex = vm.getCurrentStepIndex();
                    var previousStep = stepIndex - 1;
                    vm.selection = vm.steps[previousStep];
                }
            };



            function etatCarte() {
                $http.get('/api/members/cartePDF', {
                        responseType: 'arraybuffer',
                        params: {
                            idMembre: vm.members.id
                        }

                    })
                    .success(function(response) {
                        var file = new Blob([(response)], {
                            type: 'application/pdf'
                        });
                        fileURLPDF = URL.createObjectURL(file);
                        $window.open(fileURLPDF, '_blank');


                    });
               }





               vm.paysColumns = [{
                name: 'libele',
                title: 'Libelle'
            }];
            $scope.$on("onLoadPays", function () {

            });
            loadPays();

            function loadPays() {
                Country.query({
                    size: 10000
                }, function (data) {
                    if (data != null) {
                        vm.pays = [];
                        vm.pays = data;
                    }
                });


               // vm.members.lieuNaissance = test.libele;
            }

            $scope.$watch("vm.members.lieuNaissanceId", function () {
    if(vm.members.lieuNaissanceId!=null)
    {
                    Country.get ({
                        id: vm.members.lieuNaissanceId
                    }, function (data) {
                        vm.members.lieuNaissance = data.libele;
                    })
                }

            });





            vm.villeColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];
            $scope.$on("onLoadVilles", function () {

            });
            loadVilles();

            function loadVilles() {
                City.query({
                    size: 10000
                }, function (data) {
                    if (data != null) {

                        vm.villes = data;
                    }
                });


            }


            vm.posteColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];

            loadPostes();

            function loadPostes() {
                vm.postes = Poste.query();

        }


            $scope.$on("onLoadVillesBF", function () {

            });
            loadVillesBF();

            function loadVillesBF() {
                CityByCountryId.query ({
                    id: 1001
                }, function (data) {
                    vm.countriesVille = data;
                })


               // vm.members.lieuNaissance = test.libele;
            }


            $scope.$on("onLoadVillesUSA", function () {

            });
            loadVillesUSA();

            function loadVillesUSA() {
                CityByCountryId.query ({
                    id: 1014
                }, function (data) {
                    vm.countriesVilleUSA = data;
                })


               // vm.members.lieuNaissance = test.libele;
            }

            $scope.$watch("vm.members.bfIdDoc.cityId", function () {
                loadVillesBF();
                if(vm.countriesVille.length > 0)
                vm.members.bfIdDoc.ville = $filter('filter')(vm.countriesVille, {id: vm.members.bfIdDoc.cityId}, true)[0].libelle;

            });


            $scope.$watch("vm.members.country.cityId", function () {

                if(vm.members.country.statesId!=null)
                {
                            CityByStateId.query ({
                                    id: vm.members.country.statesId
                                }, function (data) {
                                    vm.statesVille = data;

                                    if(vm.statesVille.length > 0)
                                    vm.members.country.ville = $filter('filter')(vm.statesVille, {id: vm.members.country.cityId}, true)[0].libelle;

                                })
                            }

                        });



            $scope.$watch("vm.members.villeArriveId", function () {
      

    if(vm.members.villeArriveId!=null)
    {
                    City.get ({
                        id: vm.members.villeArriveId
                    }, function (data) {
                        vm.members.villeArrivee = data.libelle;
                        vm.members.etatArrivee = data.statesLibelle;
                    })
                }

            });

            $scope.$watch("vm.members.villeId", function () {
            
                if(vm.members.villeId!=null)
                {

                    City.get ({
                        id: vm.members.villeId
                    }, function (data) {
                        vm.members.ville = data.libelle;
                    })
                }

            });


            vm.etatColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];
            $scope.$on("onLoadEtats", function () {

            });
            loadEtats();

            function loadEtats() {
                States.query({
                    size: 10000
                }, function (data) {
                    if (data != null) {
                        vm.etats = [];
                        vm.etats = data;
                    }
                });


               // vm.members.lieuNaissance = test.libele;
            }

            vm.metierColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];
            $scope.$on("onLoadMetier", function () {

            });
            loadMetier();

            function loadMetier() {
                vm.metiers = Metier.query({
                    size: 1000
                });

            }

            $scope.$watch("vm.members.metierId", function () {
                if (vm.members.metierId != null) {
                    Metier.get({
                        id: vm.members.metierId
                    }, function (data) {
                        vm.members.metier = data.libelle;
                    })
                }

            });



            vm.zoneColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];
            $scope.$on("onLoadZone", function () {

            });
            loadZone();

            function loadZone() {
                vm.zones = Zone.query({
                    size: 1000
                });

            }

            $scope.$watch("vm.members.zoneId", function () {
                if (vm.members.zoneId != null) {
                    Zone.get({
                        id: vm.members.zoneId
                    }, function (data) {
                        vm.members.zoneLibelle = data.libelle;
                     //   vm.members.zoneId = data.id;
                    })
                }

            });



            vm.statesVilleColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];
            $scope.$on("onLoadStatesVille", function () {

            });


            $scope.$watch("vm.members.lieuNaissanceId", function () {
    if(vm.members.lieuNaissanceId!=null)
    {
                    Country.get ({
                        id: vm.members.lieuNaissanceId
                    }, function (data) {
                        vm.members.lieuNaissance = data.libele;
                    })
                }

            });


            $scope.$watch("vm.members.statesId", function () {
                if(vm.members.statesId!=null)
                {
                CityByStateId.query ({
                        id: vm.members.statesId
                    }, function (data) {
                        vm.countriesArrivee = data;
                    })

                    if(vm.etats.length > 0)
                    vm.members.etatArrivee = $filter('filter')(vm.etats, {id: vm.members.statesId}, true)[0].libelle;

                }

            });

            $scope.$watch("vm.associationId", function (ids) {

                Association.query ( function (data) {
                    vm.assocs = data;
                })

                if (angular.isDefined(ids)) {
                    vm.members.associations = [];
                    angular.forEach(ids, function (value, index) {
                        var auth = _.find(vm.assocs, { 'id': value });
                        vm.members.associations.push(auth);
                    });
                }
            });



            $scope.$watch("vm.members.country.statesId", function () {
                if(vm.members.country.statesId!=null)
                {
                CityByStateId.query ({
                        id: vm.members.country.statesId
                    }, function (data) {
                        vm.statesVille = data;
                    })

                    if(vm.etats.length > 0)
                    vm.members.country.etat = $filter('filter')(vm.etats, {id: vm.members.country.statesId}, true)[0].libelle;


                }

            });


            $scope.$watch("vm.members.adresseDTO.cityId", function () {
                if(vm.members.adresseDTO.etatId!=null)
                {
                CityByStateId.query ({
                        id: vm.members.adresseDTO.etatId
                    }, function (data) {
                        vm.villeAdresse = data;

                        if(vm.villeAdresse.length > 0)
                        vm.members.adresseDTO.city = $filter('filter')(vm.villeAdresse, {id: vm.members.adresseDTO.cityId}, true)[0].libelle;
                    })



                }

            });


           /* $scope.$watch("vm.members.adresseDTO.etatId", function () {

                CityByStateId.query ({
                        id: vm.members.adresseDTO.etatId
                    }, function (data) {
                        vm.villeAdresse = data;
                    })

            });*/

            $scope.$watch("vm.members.adresseDTO.etatId", function () {
                if(vm.members.adresseDTO.etatId!=null)
                {
                CityByStateId.query ({
                        id: vm.members.adresseDTO.etatId
                    }, function (data) {
                        vm.villeAdresse = data;
                    })

                    vm.members.adresseDTO.etat = $filter('filter')(vm.etats, {id: vm.members.adresseDTO.etatId}, true)[0].libelle;


                }

            });


            vm.colums = [{
                name: 'nom',
                title: 'Nom'
            }];


            $scope.$watch("vm.associationId", function (ids) {

                Association.query ( function (data) {
                    vm.assocs = data;
                })

                if (angular.isDefined(ids)) {
                    vm.members.associations = [];
                    angular.forEach(ids, function (value, index) {
                        var auth = _.find(vm.assocs, { 'id': value });
                        vm.members.associations.push(auth);
                    });
                }
            });

            function clear () {
                $uibModalInstance.dismiss('cancel');
            }


    }
})();
