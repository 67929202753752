(function() {
    'use strict';
    angular
        .module('consulatApp')
        .factory('City', City)
        .factory('CityByStateId', CityByStateId)
        .factory('CityByCountryId', CityByCountryId);

    City.$inject = ['$resource'];
    CityByStateId.$inject = ['$resource'];
    CityByCountryId.$inject = ['$resource'];

    function CityByCountryId ($resource) {
        var resourceUrl =  'api/cities/countryId/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function CityByStateId ($resource) {
        var resourceUrl =  'api/cities/statesId/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function City ($resource) {
        var resourceUrl =  'api/cities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
