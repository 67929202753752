(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('ConsulatDetailController', ConsulatDetailController);

    ConsulatDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Consulat', 'UserExtra', 'Adresses', 'Country', 'Members'];

    function ConsulatDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Consulat, UserExtra, Adresses, Country, Members) {
        var vm = this;

        vm.consulat = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('consulatApp:consulatUpdate', function(event, result) {
            vm.consulat = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
