(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('UserExtraDialogController', UserExtraDialogController);

    UserExtraDialogController.$inject = ['ProfilByUser','UserExtraConnected', '$scope', '$uibModalInstance', 'isEdit', 'isViewing', 'entity', 'Notification', 'User', 'Consulat', 'Profil', 'Members'];

    function UserExtraDialogController (ProfilByUser,UserExtraConnected, $scope, $uibModalInstance,isEdit, isViewing, entity, Notification, User, Consulat, Profil, Members) {

        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.test = test;
        vm.user = entity;
        vm.esDefini=null;
        vm.isEdit = isEdit;
        vm.uniteorgimanets = [];
        vm.profils= [];
        vm.modifPassword = false;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.isViewing = isViewing;
        vm.organisationId = null;
        vm.loadProgressBar = loadProgressBar;

        vm.utilisateur = UserExtraConnected.query();

        if(UserExtraConnected.query().id==null)
        {
            ProfilByUser.query({
            }, function(datas) {
                if (datas != null && datas.length==1) {
                    vm.profils = datas;
                    vm.user.profilId = vm.profils[0].id;
                }
            });
        }
    

        window.VerifaliaWidgetConfig = {
            appKey: '048e195278a540bba8758accb811a8b7'
        };



        vm.isVue = function() {
            return vm.isViewing == true;
        }

        function test() {


        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        if(!vm.isViewing && !vm.isEdit){
            vm.modifPassword = true;
        }


        function loadProfils() {
            ProfilByUser.query({
            }, function(datas) {
                if (datas != null) {
                    vm.profils = datas;
                }
            });
        }


        //Pour une modification effectué par un assistant

        var userAll = {};
            if (vm.user.id != null) {
                vm.esDefini=true;
                userAll = vm.user;
                vm.user = {};
                //Les informations de UserDTO
                vm.user.login = userAll.userDTO.login;
                vm.user.firstName = userAll.userDTO.firstName;
                vm.user.lastName = userAll.userDTO.lastName;
                vm.user.email = userAll.userDTO.email;
                vm.user.activated = userAll.userDTO.activated
                vm.user.authorities = userAll.userDTO.authorities;
                vm.user.id = userAll.userDTO.id;
                //Les informations de UserExtra
                vm.user.code = userAll.userDTO.code;
                vm.user.telephone = userAll.telephone;
                vm.user.photo = userAll.photo;
                //vm.user.userExtraId = userAll.id;
                vm.user.photoContentType = userAll.photoContentType;
                vm.user.profilId = userAll.profilId;
                vm.user.consulatId = userAll.consulatId,
                vm.user.profilNom = userAll.profilNom;
                vm.user.consulatNom = userAll.consulatNom;

            }


        function onSaveError() {
            vm.isSaving = false;

        }

        function save() {
            vm.isSaving = true;

            if (vm.user.id !== null) {
                if(vm.modifPassword && (vm.confirmPassword !== vm.user.password) ){
                    Notification.error("Le mot de passe et sa confirmation ne sont pas égaux !");
                    onSaveError();
                }else{
                User.update(vm.user, onSaveSuccess, onSaveError);
                }
            } else {
                if (vm.confirmPassword == vm.user.password) {
                    User.save(vm.user, onSaveSuccess, onSaveError);
                } else {
                    Notification.error("Le mot de passe et sa confirmation ne sont pas égaux !");
                    onSaveError();
                }
            }
        }



        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.consulatColums = [{
            name: 'code',
            title: 'Code'
        }, {
            name: 'nom',
            title: 'Nom'
        }];

        $scope.$on("onLoadConsulat", function() {
            loadConsulats()
            if(vm.utilisateur.id!=null)
            {
            vm.user.profilId=null;
            }
           vm.profils= [];
        });

        function loadConsulats() {
            Consulat.query({}, function(data) {
                if (data != null) {
                    vm.consulats = [];
                    vm.consulats = data;
                }
            });
        }




        vm.profilsColums = [{
            name: 'nom',
            title: 'Nom'
        }]

        $scope.$on("onLoadProfil", function() {
                    loadProfils();
            }
        )

        function changePasswordProgressBar(ev) {
            // less than 8 characters
            var wrost = 7,
                // minimum 8 characters
                bad = /(?=.{8,}).*/,
                //Alpha Numeric plus minimum 8
                good = /^(?=\S*?[a-z])(?=\S*?[0-9])\S{8,}$/,
                //Must contain at least one upper case letter, one lower case letter and (one number OR one special char).
                better = /^(?=\S*?[A-Z])(?=\S*?[a-z])((?=\S*?[0-9])|(?=\S*?[^\w\*]))\S{8,}$/,
                //Must contain at least one upper case letter, one lower case letter and (one number AND one special char).
                best = /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/,
                password = $(ev.target).val(),
                strength = '0',
                progressClass = 'progress-bar progress-bar-',
                ariaMsg = '0% Complete (danger)',
                $progressBarElement = $('#password-progress-bar');

            if (best.test(password) === true) {
                strength = '100%';
                progressClass += 'success';
                ariaMsg = '100% Complete (success)';
            } else if (better.test(password) === true) {
                strength = '80%';
                progressClass += 'info';
                ariaMsg = '80% Complete (info)';
            } else if (good.test(password) === true) {
                strength = '50%';
                progressClass += 'warning';
                ariaMsg = '50% Complete (warning)';
            } else if (bad.test(password) === true) {
                strength = '30%';
                progressClass += 'warning';
                ariaMsg = '30% Complete (warning)';
            } else if (password.length >= 1 && password.length <= wrost) {
                strength = '10%';
                progressClass += 'danger';
                ariaMsg = '10% Complete (danger)';
            } else if (password.length < 1) {
                strength = '0';
                progressClass += 'danger';
                ariaMsg = '0% Complete (danger)';
            }

            $progressBarElement.removeClass().addClass(progressClass);
            $progressBarElement.attr('aria-valuenow', strength);
            $progressBarElement.css('width', strength);
            $progressBarElement.find('span.sr-only').text(ariaMsg);
        }

        function loadProgressBar() {
            $(document).ready(function () {
                $(".pwd").first().on('keyup', changePasswordProgressBar);
            });
        }

        loadProgressBar();
    }
})();
