(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MetierController', MetierController);

    MetierController.$inject = ['$scope', 'Metier', 'TableService'];

    function MetierController($scope, Metier, TableService) {

        var vm = this;

        var addItemData ={
            templateUrl: 'app/entities/metier/metier-dialog.html',
            controller: 'MetierDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'mg',
            resolve: {
                entity: function () {
                    return {
                        id: null,
                        libelle: null
                    };
                }
            }
        };

        /**
         *Modification d'un élément
         */
        var editItemData = {
            templateUrl: 'app/entities/metier/metier-dialog.html',
            controller: 'MetierDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData ={
            templateUrl: 'app/entities/metier/metier-delete-dialog.html',
            controller: 'MetierDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

          var detailItemData = {
            templateUrl: 'app/entities/metier/metier-detail.html',
            controller: 'MetierDetailController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null, //valeur déterminé à l'appel de la boite
                 isViewing: true
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData,
            detailData: detailItemData
        };
        var entitySearchParams = function() {
            return {

            };
        };

        var entityParams = function() {
            return {

            };
        };

        var datas = {
            scope: $scope,
            vm: vm,
            entity: Metier,
            entitySearch: Metier,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
    }
})();