(function() {
    'use strict';
    angular
        .module('consulatApp')
        .factory('AssociationFiltre', AssociationFiltre)
        .factory('TypeAssociation', TypeAssociation)
        .factory('Association', Association);



    Association.$inject = ['$resource'];
    TypeAssociation.$inject = ['$resource'];
    AssociationFiltre.$inject = ['$resource'];



    function AssociationFiltre ($resource, DateUtils) {
        var resourceUrl =  'api/associations/findByParams';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateCreation = DateUtils.convertLocalDateFromServer(data.dateCreation);
                    }
                    return data;
                }
            }
        });
    }


    function TypeAssociation ($resource) {
        var resourceUrl =  'api/type-associations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }





    function Association ($resource) {
        var resourceUrl =  'api/associations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
