(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('CarteDialogController', CarteDialogController);

    CarteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Carte', 'Members','isViewing','Adresses'];

    function CarteDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Carte, Members,isViewing,Adresses) {
        var vm = this;

        vm.carte = entity;
        vm.isViewing = isViewing;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.adresse = {};
        vm.members = Members.query();

        if(vm.carte.adresseId){
            Adresses.get({
                id: vm.carte.adresseId
            }, function (data) {
                vm.adresse = data;
            }, function (error) {});
        }
        if(vm.carte.dateCreate!=null){
            vm.carte.dateCreate = new Date(vm.carte.dateCreate);
        }
        if(vm.carte.dateNaissance!=null){
            vm.carte.dateNaissance = new Date(vm.carte.dateNaissance);
        }
        if(vm.carte.dateExpr!=null){
            vm.carte.dateExpr = new Date(vm.carte.dateExpr);
        }
        if(vm.carte.dateAnnulation!=null){
            vm.carte.dateAnnulation = new Date(vm.carte.dateAnnulation);
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.carte.id !== null) {
                Carte.update(vm.carte, onSaveSuccess, onSaveError);
            } else {
                Carte.save(vm.carte, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('consulatApp:carteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dateCreate = false;
        vm.datePickerOpenStatus.dateExpr = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
