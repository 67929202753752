(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal'];

    function PasswordController (Auth, Principal) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.loadProgressBar = loadProgressBar;


        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }


        function changePasswordProgressBar(ev) {
            // less than 8 characters
            var wrost = 7,
                // minimum 8 characters
                bad = /(?=.{8,}).*/,
                //Alpha Numeric plus minimum 8
                good = /^(?=\S*?[a-z])(?=\S*?[0-9])\S{8,}$/,
                //Must contain at least one upper case letter, one lower case letter and (one number OR one special char).
                better = /^(?=\S*?[A-Z])(?=\S*?[a-z])((?=\S*?[0-9])|(?=\S*?[^\w\*]))\S{8,}$/,
                //Must contain at least one upper case letter, one lower case letter and (one number AND one special char).
                best = /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/,
                password = $(ev.target).val(),
                strength = '0',
                progressClass = 'progress-bar progress-bar-',
                ariaMsg = '0% Complete (danger)',
                $progressBarElement = $('#password-progress-bar');

            if (best.test(password) === true) {
                strength = '100%';
                progressClass += 'success';
                ariaMsg = '100% Complete (success)';
            } else if (better.test(password) === true) {
                strength = '80%';
                progressClass += 'info';
                ariaMsg = '80% Complete (info)';
            } else if (good.test(password) === true) {
                strength = '50%';
                progressClass += 'warning';
                ariaMsg = '50% Complete (warning)';
            } else if (bad.test(password) === true) {
                strength = '30%';
                progressClass += 'warning';
                ariaMsg = '30% Complete (warning)';
            } else if (password.length >= 1 && password.length <= wrost) {
                strength = '10%';
                progressClass += 'danger';
                ariaMsg = '10% Complete (danger)';
            } else if (password.length < 1) {
                strength = '0';
                progressClass += 'danger';
                ariaMsg = '0% Complete (danger)';
            }

            $progressBarElement.removeClass().addClass(progressClass);
            $progressBarElement.attr('aria-valuenow', strength);
            $progressBarElement.css('width', strength);
            $progressBarElement.find('span.sr-only').text(ariaMsg);
        }

        function loadProgressBar() {
            $(document).ready(function () {
                $(".pwd").first().on('keyup', changePasswordProgressBar);
            });
        }

        loadProgressBar();








    }
})();
