(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('ConsulatController', ConsulatController);

    ConsulatController.$inject = ['$scope', 'TableService', 'Consulat', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function ConsulatController($scope, TableService, Consulat, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        var addItemData ={
           templateUrl: 'app/entities/consulat/consulat-dialog.html',
           controller: 'ConsulatDialogController',
           controllerAs: 'vm',
           backdrop: 'static',
           size: 'lg',
           resolve: {
               entity: function () {
                   return {
                       id: null,
                       code:null,
                       nom:null,
                       adressesId:null,
                            
        adresseDTO:{
            rue:null,
            noPorte:null,
            telephone:null,
            mail:null,
            zipCode:null,
            autre:null,
            cityId:null,
            etatId:null
        }
    
                       
                   };
               }
           }
       };
    
       /**
        *Modification d'un élément
        */
       var editItemData = {
           templateUrl: 'app/entities/consulat/consulat-dialog.html',
           controller: 'ConsulatDialogController',
           controllerAs: 'vm',
           backdrop: 'static',
           size: 'lg',
           resolve: {
               entity: null
           }
       };

       /**
        * Suppresion d'un élément
        */
       var deleteItemData ={
           templateUrl: 'app/entities/mariage/consulat-delete-dialog.html',
           controller: 'ConsulatDeleteController',
           controllerAs: 'vm',
           size: 'sm',
           resolve: {
               entity: null
           }
       };

         var detailItemData = {
           templateUrl: 'app/entities/consulat/consulat-detail.html',
           controller: 'ConsulatDetailController',
           controllerAs: 'vm',
           backdrop: 'static',
           size: 'md',
           resolve: {
               entity: null, //valeur déterminé à l'appel de la boite
                isViewing: true
           }
       };

       var dialogData = {
           addData: addItemData,
           editData: editItemData,
           deleteData: deleteItemData,
           detailData: detailItemData
       };
       var entitySearchParams = function() {
           return {

           };
       };

       var entityParams = function() {
           return {

           };
       };

       var datas = {
           scope: $scope,
           vm: vm,
           entity: Consulat,
           entitySearch: Consulat,
           dialogData: dialogData,
           entitySearchParams: entitySearchParams,
           entityParams: entityParams
       };

       TableService.init(datas);
   }
})();
