(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('UserExtraController', UserExtraController);

    UserExtraController.$inject = [ '$scope','UserExtra', 'Principal','TableService'];

    function UserExtraController( $scope,UserExtra, Principal,TableService) {

        var vm = this;
        var datas = {};
   
      
        vm.currentAccount=null;
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });
        vm.setActive=function setActive (userExtra, isActivated) {
           var user = userExtra.userDTO;
            user.activated = isActivated;
            user.telephone=userExtra.userExtraDTO.telephone;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }
        


          function _update(srcObj, destObj) {
            for (var key in destObj) {
                destObj[key] = srcObj[key];
            }
          }


        var addItemData = {
            templateUrl: 'app/entities/user-extra/user-extra-dialog.html',
            controller: 'UserExtraDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: function () {
                    return {
                        code: null,
                        telephone: null,
                        photo: null,
                        photoContentType: null,
                        id: null,
                        login: null,
                        firstName: null,
                        lastName: null,
                        email: null,
                        activated: true,
                        langKey: null,
                        createdBy: null,
                        createdDate: null,
                        lastModifiedBy: null,
                        lastModifiedDate: null,
                        profilId:null,
                        consulatId:null
                        //agenceId:vm.agencePolice.id
                    };
                },
                isViewing: function () {
                    return false;
                },
                isEdit: function () {
                    return false;
                }
            }
        };

        /**
         *Modification d'un élément
         */

        var editItemData = {
            templateUrl: 'app/entities/user-extra/user-extra-dialog.html',
            controller: 'UserExtraDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                entity: null,
                isViewing: function () {
                    return false;
                },
                isEdit: function () {
                    return true;
                }
            }
        };

        /**
         * Suppresion d'un élément
         */
        var deleteItemData = {
            templateUrl: 'app/entities/user-extra/user-extra-delete-dialog.html',
            controller: 'UserExtraDeleteController',
            controllerAs: 'vm',
            size: 'sm',
            resolve: {
                entity: null
            }
        };

        var detailItemData = {
            templateUrl: 'app/entities/user-extra/user-extra-dialog.html',
            controller: 'UserExtraDialogController',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                ACTION: function () {
                    return vm.action;
                },
                isViewing: function () {
                    return true;
                },
                isEdit: function () {
                    return false;
                },
                entity: null //valeur déterminé à l'appel de la boite,
            }
        };

        var dialogData = {
            addData: addItemData,
            editData: editItemData,
            deleteData: deleteItemData,
            detailData: detailItemData
        };
        var entitySearchParams = function () {
            return {

            };
        };

        var entityParams = function () {
            return{
                organisationId:vm.organisationId
            };
        };

            datas = {
                scope: $scope,
                vm: vm,
                entity: UserExtra,
                entitySearch: UserExtra,
                dialogData: dialogData,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            TableService.init(datas);

            vm.initInfo = function() {
                vm.loadAll();
            };
    }
})();
