(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MembreServiceDialogController', MembreServiceDialogController);

    MembreServiceDialogController.$inject = ['UserExtraConnected','$timeout', '$scope', '$uibModalInstance', 'entity', 'MembreService', 'Association','States','CityByStateId','StatesByCountry'];

    function MembreServiceDialogController (UserExtraConnected,$timeout, $scope, $uibModalInstance, entity, MembreService, Association,States,CityByStateId,StatesByCountry) {
        var vm = this;

        vm.membreService = entity;
        vm.clear = clear;
        vm.save = save;
        vm.init =init;
        vm.user = UserExtraConnected.query();

        vm.associations = Association.query();

if(vm.membreService.id!=null)
{
    vm.membreService.date = new Date( vm.membreService.date);

}
function init()
{
    vm.membreService.consulat=null;
}



        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.membreService.id !== null) {
                MembreService.update(vm.membreService, onSaveSuccess, onSaveError);
            } else {
                MembreService.save(vm.membreService, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('consulatApp:countryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

          vm.etatColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];
            $scope.$on("onLoadEtats", function () {
    
            });
    loadEtats();
            function loadEtats() {
                States.query({
                    size: 10000
                }, function (data) {
                    if (data != null) {
                        vm.etats = [];
                        vm.etats = data;
                    }
                });
    
                
               // vm.members.lieuNaissance = test.libele;
            }
    
    
            vm.statesVilleColumns = [{
                name: 'libelle',
                title: 'Libelle'
            }];
            $scope.$on("onLoadStatesVille", function () {
    
            });


            $scope.$watch("vm.membreService.etatId", function () {
                loadEtats();
        if(vm.membreService.etatId!=null )
        {
                CityByStateId.query ({
                        id: vm.membreService.etatId
                    }, function (data) {
                        vm.villeAdresse = data;
                    })
                }
                
            });


    }
})();
