(function() {
    'use strict';

    angular
        .module('consulatApp')
        .factory('ConfirmService', ConfirmService);

    ConfirmService.$inject = ['$uibModal'];

    function ConfirmService ($uibModal) {
        return {
            open: open
        };

        function open(text, onOk) {
        var modalInstance = $uibModal.open({
            templateUrl: 'app/components/confirmDialog/confirm-dialog.html',
            controller: 'ModalConfirmCtrl',
            resolve: {
                text: function () {
                    return text;
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            onOk();
        }, function () {
        });
    }
    }
})();
