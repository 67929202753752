(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('HomeElectionController', HomeElectionController);

        HomeElectionController.$inject = ['$scope', 'Principal','DateUtils','$location', 'LoginService', '$state','MembreServiceTop10','MembreServiceTop10Even','$rootScope','Auth','$http','ConsulatTop1'];

    function HomeElectionController ($scope, Principal,DateUtils,$location, LoginService, $state,MembreServiceTop10,MembreServiceTop10Even,$rootScope,Auth,$http,ConsulatTop1) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm.periodeDebut = null;
        vm.periodeFin = null;
        vm.token = null;
        vm.otp = null;
        vm.getOtp = getOtp;
        vm.codeOtp = null;



        vm.authenticationError = false;
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.phoneNumber = null;

        const user = {

          };
        vm.fakeUser = {
            name: "user",     
            phone: "", 
            authorities: ["ROLE_VOTE"] 
        }



        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }



        // $scope.$on('authenticationSuccess', function() {
        //     getAccount();
        // });

        // getAccount();

        // function getAccount() {

        //     Principal.identity().then(function(account) {
        //         vm.account = account;
        //         vm.isAuthenticated = Principal.isAuthenticated;
        //         if(account!=null){
        //             vm.consulat = ConsulatTop1.get();
        //             console.log(vm.account);
        //         var result = vm.account.authorities.find(function(n){
        //             return n==='ROLE_MEMBRE';
        //         });

        //         if(result=="ROLE_MEMBRE"){
        //             vm.manageCharts();

        //         }
        //         }


        //     });
        // }
        function register () {
            $state.go('register');
        }

        function getOtp () {
             $http.post('api/sendOtp', { phoneNumber: vm.phoneNumber }).then(function (response) {
                vm.otp = response.data;
                console.log(response.data);
            });


            console.log(vm.otp);

            // function authenticateSuccess (data, status, headers) {

            //     var bearerToken = headers('Authorization');
            //     if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
            //         console.log("oioioioioioi");

            //         var jwt = bearerToken.slice(7, bearerToken.length);
            //         service.storeAuthenticationToken(jwt, phone.rememberMe);
            //         return jwt;
            //     }
            // }
        }

        function login (event) {
            event.preventDefault();
            console.log(vm.phoneNumber)
            var credentials = {
                phoneNumber: vm.phoneNumber,     
                code: vm.codeOtp, 
            }
            Auth.loginPhone(credentials).then(function (response) {
                
                //vm.token = response.data.id_token;
                
                // Principal.authenticate(vm.fakeUser).then(function() {
                //    console.log(account);
                // $location.path('/vote').search({ userPhone: vm.phoneNumber });
                 $state.go('vote', { userPhone: vm.phoneNumber });
    
                // });               
            }).catch(function () {
                vm.authenticationError = true;
            });

        }


        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        function getAccount() {

            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if(account!=null){
                    console.log(vm.account);
                var result = vm.account.authorities.find(function(n){
                    return n==='ROLE_MEMBRE';
                });
                }


            });
        }



        function requestResetPassword () {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }

    }
})();
