(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('AssociationController', AssociationController);

    AssociationController.$inject = ['$scope','DataUtils','DateUtils', 'Association', 'TableService','AssociationFiltre'];

    function AssociationController($scope,DataUtils,DateUtils, Association, TableService,AssociationFiltre) {

        var vm = this;
        vm.init = init;
        vm.datePickerOpenStatus = {};
        vm.openFile = DataUtils.openFile;
        vm.openCalendar = openCalendar;


        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        vm.itemSearch = {
            "nom": null,
            "type": null,
            "adresse": null,
            "dateCreation": null
        };


        vm.initFiltre = function () {
            vm.filtrer = !vm.filtrer;
            if (!vm.filtrer) {
                vm.itemSearch = {
                    "nom": null,
                    "type": null,
                    "dateCreation": null,
                    "adresse": null
                     
                };
                init();
            }
        }



          init();
        function init() {
            if(vm.itemSearch.dateCreation!=null)
            vm.itemSearch.dateCreation = DateUtils.convertLocalDateToServer(vm.itemSearch.dateCreation);


            var addItemData = {};

            var editItemData = {};

            var addItemData ={
                templateUrl: 'app/entities/association/association-dialog.html',
                controller: 'AssociationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            numero:null,
                            nom:null,
                            type:null,
                            siege:null,
                            adresseId:null,
                                 
             adresseDTO:{
                 rue:null,
                 noPorte:null,
                 telephone:null,
                 mail:null,
                 zipCode:null,
                 autre:null,
                 cityId:null,
                 etatId:null
             }
         
                            
                        };
                    }
                }
            };
         
            /**
             *Modification d'un élément
             */
            var editItemData = {
                templateUrl: 'app/entities/association/association-dialog.html',
                controller: 'AssociationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: null
                }
            };
     
            /**
             * Suppresion d'un élément
             */
            var deleteItemData ={
                templateUrl: 'app/entities/association/association-delete-dialog.html',
                controller: 'AssociationDeleteController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: null
                }
            };
     
              var detailItemData = {
                templateUrl: 'app/entities/association/association-detail.html',
                controller: 'AssociationDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: null, //valeur déterminé à l'appel de la boite
                     isViewing: true
                }
            };

            var dialogData = {
                addData: addItemData,
                editData: editItemData,
                detailData: detailItemData
            };
            var entitySearchParams = function () {
                return {};
            };
            var entityParams = function () {
                return {
                    "nom": vm.itemSearch.nom,
                    "type": vm.itemSearch.type,
                    "dateCreation": vm.itemSearch.dateCreation,
                    "adresse": vm.itemSearch.adresse
                    
                    
                };
            };


            var datas = {
                scope: $scope,
                vm: vm,
                entity: AssociationFiltre,
                dialogData: dialogData,
                entitySearch: AssociationFiltre,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            TableService.init(datas);
        }

















        var addItemData ={
           templateUrl: 'app/entities/association/association-dialog.html',
           controller: 'AssociationDialogController',
           controllerAs: 'vm',
           backdrop: 'static',
           size: 'lg',
           resolve: {
               entity: function () {
                   return {
                       id: null,
                       numero:null,
                       nom:null,
                       type:null,
                       siege:null,
                       adresseId:null,
                            
        adresseDTO:{
            rue:null,
            noPorte:null,
            telephone:null,
            mail:null,
            zipCode:null,
            autre:null,
            cityId:null,
            etatId:null
        }
    
                       
                   };
               }
           }
       };
    
       /**
        *Modification d'un élément
        */
       var editItemData = {
           templateUrl: 'app/entities/association/association-dialog.html',
           controller: 'AssociationDialogController',
           controllerAs: 'vm',
           backdrop: 'static',
           size: 'lg',
           resolve: {
               entity: null
           }
       };

       /**
        * Suppresion d'un élément
        */
       var deleteItemData ={
           templateUrl: 'app/entities/association/association-delete-dialog.html',
           controller: 'AssociationDeleteController',
           controllerAs: 'vm',
           size: 'sm',
           resolve: {
               entity: null
           }
       };

         var detailItemData = {
           templateUrl: 'app/entities/association/association-detail.html',
           controller: 'AssociationDetailController',
           controllerAs: 'vm',
           backdrop: 'static',
           size: 'md',
           resolve: {
               entity: null, //valeur déterminé à l'appel de la boite
                isViewing: true
           }
       };

       var dialogData = {
           addData: addItemData,
           editData: editItemData,
           deleteData: deleteItemData,
           detailData: detailItemData
       };
       var entitySearchParams = function() {
           return {

           };
       };

       var entityParams = function() {
           return {

           };
       };

       var datas = {
           scope: $scope,
           vm: vm,
           entity: Association,
           entitySearch: Association,
           dialogData: dialogData,
           entitySearchParams: entitySearchParams,
           entityParams: entityParams
       };

       TableService.init(datas);
   }
})();
