(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('CarteDetailController', CarteDetailController);

    CarteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Carte', 'Members'];

    function CarteDetailController($scope, $rootScope, $stateParams, previousState, entity, Carte, Members) {
        var vm = this;

        vm.carte = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('consulatApp:carteUpdate', function(event, result) {
            vm.carte = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
