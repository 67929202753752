(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('VoteController', VoteController);

        VoteController.$inject = ['$scope','Consulat','$stateParams','TableService','MemberCandidats','MembersSaveVote','Auth'];

    function VoteController($scope,Consulat,$stateParams,TableService,MemberCandidats,MembersSaveVote,Auth) {

        var vm = this;
        vm.voter = voter;
        vm.userPhone = $stateParams.userPhone;
        vm.vote = false;
        vm.logout = logout;

       function voter(candidatId)
        {
       
                MembersSaveVote.get({
                    candidatId: candidatId,
                    phone: vm.userPhone
                }, function (data) {
                    vm.vote = data.voted;
                    vm.logout();
                })

               

        }

        function logout() {
            Auth.logout();
        }
        // $scope.$watch("vm.voter", function () {
        //     if (vm.members.voter) {
        //         Country.get({
        //             id: vm.members.lieuNaissanceId
        //         }, function (data) {
        //             vm.members.lieuNaissance = data.libele;
        //         })

        //     }

        // });

        var entitySearchParams = function() {
            return {

            };
        };

        var entityParams = function() {
            return {
                "phone": vm.userPhone

            };
        };

        var dialogData = null;

        var datas = {
            scope: $scope,
            vm: vm,
            entity: MemberCandidats,
            entitySearch: MemberCandidats,
            dialogData: dialogData,
            entitySearchParams: entitySearchParams,
            entityParams: entityParams
        };
        TableService.init(datas);
       

    }
})();