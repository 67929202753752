(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('ZoneDialogController', ZoneDialogController);

    ZoneDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Zone', 'StatesAll', 'StatesByZone'];

    function ZoneDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,Zone, StatesAll, StatesByZone) {
        var vm = this;

        vm.zone = entity;

        vm.clear = clear;
        vm.save = save;
        vm.states = StatesAll.query();


        if(vm.zone.id!=null)
        {
        StatesByZone.query({
            id: vm.zone.id
        }, function (data) {
            vm.zone.etats = data ;
        })
    }
       

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.zone.id !== null) {
                Zone.update(vm.zone, onSaveSuccess, onSaveError);
            } else {
                Zone.save(vm.zone, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('consulatApp:zoneUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.colums = [{
            name: 'libelle',
            title: 'Libelle'
        }];


        $scope.$watch("vm.etatId", function (ids) {

            StatesAll.query(function (data) {
                vm.etats = data;
                //console.log(vm.etats);
            })


            if (angular.isDefined(ids)) {
                vm.zone.etats = [];
                angular.forEach(ids, function (value, index) {
                    var auth = _.find(vm.etats, {'id': value});
                    vm.zone.etats.push(auth);
                });
            }
        });


    }
})();
