(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MembersStatsController', MembersStatsController);

        MembersStatsController.$inject = ['$scope','$http','$window','DateUtils','TableService',
            'MemberMultiParams','City','UserExtraConnected','DocumentLegal','Consulat','Notification','Zone'];

    function MembersStatsController($scope,$http,$window,DateUtils,TableService,MemberMultiParams,City,
                                    UserExtraConnected,DocumentLegal,Consulat,Notification, Zone) {
        var vm = this;

        vm.init = init;

        var fileURLPDF;
        vm.documents = DocumentLegal.query();
        vm.consulats = Consulat.query();
        vm.zones = Zone.query();
        vm.user = UserExtraConnected.query();
        vm.etatCarte = etatCarte;


        function etatCarte(id) {
            $http.get('/api/members/cartePDF', {
                    responseType: 'arraybuffer',
                    params: {
                        idMembre:id,
                        changement:true

                    }
                })
                .success(function(response) {
                    var file = new Blob([(response)], {
                        type: 'application/pdf'
                    });
                    fileURLPDF = URL.createObjectURL(file);
                    $window.open(fileURLPDF, '_blank');
                })
                .error(function(data, status) {
                    if(status===400){
                        Notification.error("Vous ne pouvez pas generer une autre carte valide");
                    }
                });
           }

        vm.itemSearch = {
            "nom": null,
            "prenom": null,
            "numeroBf": null,
            "telephone": null,
            "dateArrivee": null,
            "mariage": null,
            "sexe": null,
            "metier": null,
            "occupation": null,
            "parentsUsa": null,
            "mumInUsa": null,
            "autorite": null,
            "villeResidence": null,
            "etatResidence": null,
            "consulatId": null,
            "candidate": null,
            "zoneId": null

        };
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateDebut = false;
        vm.datePickerOpenStatus.dateFin = false;
        vm.filtrer=false;

        loadVilles();

        function loadVilles() {
            City.query({
                size: 10000
            }, function (data) {
                if (data != null) {
                    vm.villes = data;
                }
            });
           // vm.members.lieuNaissance = test.libele;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        vm.initFiltre = function () {
            vm.filtrer = !vm.filtrer;
            if (!vm.filtrer) {
                vm.itemSearch = {
                    "nom": null,
                    "prenom": null,
                    "numeroBf": null,
                    "telephone": null,
                    "dateArrivee": null,
                    "mariage": null,
                    "sexe": null,
                    "metier": null,
                    "occupation": null,
                    "parentsUsa": null,
                    "mumInUsa": null,
                    "autorite": null,
                    "villeResidence": null,
                    "etatResidence": null,
                    "consulatId": null,
                    "candidate": null,
                    "zoneId": null,

                };
                init();
            }
        }

          init();
        function init() {
            if(vm.itemSearch.dateArrivee!=null)
            vm.date = null;
            vm.date = DateUtils.convertLocalDateToServer(vm.itemSearch.dateArrivee);
            var addItemData = {};
            var editItemData = {};
            /**
             *Modification d'un élément
            */
       var editItemData = {
        templateUrl: 'app/entities/members/members-detail.html',
        controller: 'MembersDetailController',
        controllerAs: 'vm',
        backdrop: 'static',
        size: 'lg',
        resolve: {
            entity: null
        }
    };

            var detailItemData = {
                templateUrl: 'app/entities/members/members-dialog.html',
                controller: 'MembersDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    isViewing: function () {
                        return true;
                    },
                    entity: null //valeur déterminé à l'appel de la boite,
                }
            };

            var deleteItemData = {};
            var dialogData = {
                addData: addItemData,
                editData: editItemData,
                detailData: detailItemData
            };
            var entitySearchParams = function () {
                return {};
            };
            var entityParams = function () {
                return {
                    "nom": vm.itemSearch.nom,
                    "prenom": vm.itemSearch.prenom,
                    "numeroBf": vm.itemSearch.numeroBf,
                    "telephone": vm.itemSearch.telephone,
                    "dateArrivee": vm.date,
                    "mariage": vm.itemSearch.mariage,
                    "sexe": vm.itemSearch.sexe,
                    "metier": vm.itemSearch.metier,
                    "occupation": vm.itemSearch.occupation,
                    "parentsUsa": vm.itemSearch.parentsUsa,
                    "autorite": vm.itemSearch.autorite,
                    "villeResidence": vm.itemSearch.villeResidence,
                    "etatResidence": vm.itemSearch.etatResidence ,
                    "consulatId": vm.itemSearch.consulatId,
                    "candidate": vm.itemSearch.candidate,
                    "zoneId": vm.itemSearch.zoneId
                };
            };


            var datas = {
                scope: $scope,
                vm: vm,
                entity: MemberMultiParams,
                dialogData: dialogData,
                entitySearch: MemberMultiParams,
                entitySearchParams: entitySearchParams,
                entityParams: entityParams
            };
            TableService.init(datas);
        }
    }

})();
