(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('MembersVoteResultatController', MembersVoteResultatController);

        MembersVoteResultatController.$inject = ['$scope','$http','Zone'];

    function MembersVoteResultatController($scope,$http,Zone) {
        var vm = this;


        vm.zoneId = null;
        vm.init = init;
        vm.zones = [];
        vm.candidats = [];
        vm.zones = Zone.query();
        console.log(vm.zones);
        vm.etatCandidat = {};

            
        function init() {

            $http.get("api/statistiques/etatCandidats", {params: {zoneId: vm.zoneId }}).then(
                function(response) {
                    console.log(response.data);
                    vm.etatCandidat.labels = response.data.candidats;
                    vm.etatCandidat.data = response.data.data;
                }
            );
        }


        $scope.$watch("vm.zoneId", function () {
            if(vm.zoneId !=null)
            {
                vm.init();

            }
        });
    }

})();
