(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('StatesDetailController', StatesDetailController);

    StatesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'States', 'City', 'Country'];

    function StatesDetailController($scope, $rootScope, $stateParams, previousState, entity, States, City, Country) {
        var vm = this;

        vm.states = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('consulatApp:statesUpdate', function(event, result) {
            vm.states = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
