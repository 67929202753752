(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'User','isViewing','isEdit'];

    function UserManagementDialogController ($scope, $uibModalInstance, entity, User,isViewing,isEdit) {
        var vm = this;

        vm.clear = clear;
        vm.isViewing = isViewing;
        vm.isEdit = isEdit;
        vm.languages = null;
        vm.profils = null;
        vm.uniteOrganisations = null;
        vm.save = save;
        vm.user = entity;
        vm.authorities = [];
        vm.personnes = [];
        vm.modifPassword = false;
        vm.loadProgressBar = loadProgressBar;


        vm.profilsColums = [{
            name: 'nom',
            title: 'Nom'
        }, {
            name: 'organisationNom',
            title: 'Organisation'
        }];

        $scope.$on("onLoadProfil", function() {
            if (vm.user.uniteOrganisationId != null) {
                if (vm.user.uniteOrganisationId != "") {
                    loadProfils();
                }
            }
        });


        function loadProfils() {
            RequestUtils.GetProfilsCreatedByUserConnectedAndOrganisation.query({
                id: vm.user.uniteOrganisationId
            }, function(datas) {
                console.log("profils");
                console.log(datas);
                if (datas != null) {
                    vm.profils = datas;
                    console.log(vm.profils);
                    console.log(vm.user.profilId);
                }
            });
        }

        function loadConsulats() {
            vm.personnes = PersonneTotalName.query();
            console.log(vm.personnes);
        }

        vm.consulatColums = [{
            name: 'nom',
            title: 'Nom'
        }, {
            name: 'dateNaissance',
            title: 'date de naissance'
        }];

        $scope.$on("onLoadConsulats", function() {
            loadPersonnes();
        });


        if(!vm.isViewing && !vm.isEdit){
            vm.modifPassword = true;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save() {
            vm.user.langKey = "fr";
            vm.isSaving = true;
            console.log(vm.user);
            if (vm.user.id !== null) {
                if(vm.modifPassword && (vm.confirmPassword !== vm.user.password) ){
                    Notification.error("Le mot de passe et sa confirmation ne sont pas égaux !");
                    onSaveError();
                }else{
                    console.log("mise à jour");
                    User.update(vm.user, onSaveSuccess, onSaveError);
                }
            } else {
                if (vm.confirmPassword == vm.user.password) {
                    User.save(vm.user, onSaveSuccess, onSaveError);
                } else {
                    Notification.error("Le mot de passe et sa confirmation ne sont pas égaux !");
                    onSaveError();
                }
            }
        }

        if (vm.user.id != null) {
            console.log("Toutes les informations-----------------------------------------");
            console.log(vm.user.uniteOrganisationId);
            loadOrganisations();
            loadProfils();
            loadPersonnes();

        }



        function changePasswordProgressBar(ev) {
            // less than 8 characters
            var wrost = 7,
                // minimum 8 characters
                bad = /(?=.{8,}).*/,
                //Alpha Numeric plus minimum 8
                good = /^(?=\S*?[a-z])(?=\S*?[0-9])\S{8,}$/,
                //Must contain at least one upper case letter, one lower case letter and (one number OR one special char).
                better = /^(?=\S*?[A-Z])(?=\S*?[a-z])((?=\S*?[0-9])|(?=\S*?[^\w\*]))\S{8,}$/,
                //Must contain at least one upper case letter, one lower case letter and (one number AND one special char).
                best = /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/,
                password = $(ev.target).val(),
                strength = '0',
                progressClass = 'progress-bar progress-bar-',
                ariaMsg = '0% Complete (danger)',
                $progressBarElement = $('#password-progress-bar');

            if (best.test(password) === true) {
                strength = '100%';
                progressClass += 'success';
                ariaMsg = '100% Complete (success)';
            } else if (better.test(password) === true) {
                strength = '80%';
                progressClass += 'info';
                ariaMsg = '80% Complete (info)';
            } else if (good.test(password) === true) {
                strength = '50%';
                progressClass += 'warning';
                ariaMsg = '50% Complete (warning)';
            } else if (bad.test(password) === true) {
                strength = '30%';
                progressClass += 'warning';
                ariaMsg = '30% Complete (warning)';
            } else if (password.length >= 1 && password.length <= wrost) {
                strength = '10%';
                progressClass += 'danger';
                ariaMsg = '10% Complete (danger)';
            } else if (password.length < 1) {
                strength = '0';
                progressClass += 'danger';
                ariaMsg = '0% Complete (danger)';
            }

            $progressBarElement.removeClass().addClass(progressClass);
            $progressBarElement.attr('aria-valuenow', strength);
            $progressBarElement.css('width', strength);
            $progressBarElement.find('span.sr-only').text(ariaMsg);
        }

        function loadProgressBar() {
            $(document).ready(function () {
                $(".pwd").first().on('keyup', changePasswordProgressBar);
            });
        }

        loadProgressBar();

    }
})();
