(function() {
    'use strict';

    angular
        .module('consulatApp')
        .controller('PosteDetailController', PosteDetailController);

    PosteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Poste', 'InfoPoste'];

    function PosteDetailController($scope, $rootScope, $stateParams, previousState, entity, Poste, InfoPoste) {
        var vm = this;

        vm.poste = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('consulatApp:posteUpdate', function(event, result) {
            vm.poste = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
